exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-js": () => import("./../../../src/pages/coming.js" /* webpackChunkName: "component---src-pages-coming-js" */),
  "component---src-pages-competicao-programacao-js": () => import("./../../../src/pages/competicao-programacao.js" /* webpackChunkName: "component---src-pages-competicao-programacao-js" */),
  "component---src-pages-equipa-js": () => import("./../../../src/pages/equipa.js" /* webpackChunkName: "component---src-pages-equipa-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programa-js": () => import("./../../../src/pages/programa.js" /* webpackChunkName: "component---src-pages-programa-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-templates-session-js": () => import("./../../../src/templates/session.js" /* webpackChunkName: "component---src-templates-session-js" */),
  "component---src-templates-talk-js": () => import("./../../../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */)
}

